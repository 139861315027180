import store from '@/store'
 
const directivePlugin = {
  install(Vue) {
    Vue.directive('checkBtn', {
      inserted(el, binding) {
        // el: 指令绑定的那个元素对象 dom
        // binding.value:  指令等于号后面绑定的表达式的值  v-if="xxx"
        // 拿到el 拿到value 配合权限数据 做按钮显示隐藏控制
        // 控制逻辑: 判断当前的按钮自身的权限标识能否在后端返回的points中找到 如果找到 证明要显示
        // 如果找不到 证明要隐藏
        
        // 1. 权限数据 -> points  
        // 2. binding.value -> 按钮自身的标识
        let flag = true
        const points = store.state.system.menu.btnRoles
        const isAdmin = store.state.user.user.isAdmin
        const userId = store.state.user.user && store.state.user.user.userInfo ? store.state.user.user.userInfo.id : ''
        // 直接跳过的判断
        if (Array.isArray(binding.value) && binding.value[0]) {
          if (binding.value[1] && binding.value[1] === 'system' && !binding.value[0].includes('edit') && isAdmin) {
            el.remove()
          } else {
            if (binding.value[0].includes('true-')) {
              flag = false
            }
          }
        } else if (binding.value && binding.value.includes('true-')) {
          flag = false
        }
        if (flag) {
          if (Array.isArray(binding.value)) {
            if (!points.includes(binding.value[0])) {
              // 把当前的按钮移除
              // 移除eldom 1. remove()  2. 先找到父节点 removeChild
              el.remove()
            }
            // 如果是宝宝请假 只能学生的老师审批 
            const arr = ['approve', 'reject'] // 需要判断的按钮
            if (binding.value[0].includes('ask') && arr.includes(binding.value[0].replace(/ask-/g, '')) && binding.value[2] && binding.value[2] !== userId) {
              el.remove()
            }
            if (binding.value[1] && binding.value[1] === 'system' && !binding.value[0].includes('edit') && isAdmin) {
              el.remove()
            }
          } else {
            if (!points.includes(binding.value)) {
              el.remove()
            }
          }
        }
      }
    })
  }
 
}
 
export default directivePlugin