<template>
  <div class="search">
    <div class="wrap">
      <div class="group">
        <el-row>
          <slot></slot>
          <el-col class="item" :span="8">
            <div class="box">
              <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
              <el-button icon="el-icon-refresh" @click="handleClear">重置</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SearchForm',
    methods: {
      handleSearch(evt) {
        this.$emit('search', evt)
      },
      handleClear(evt) {
        this.$emit('clear', evt)
      }
    }
  }
</script>
<style scoped>
 .search {
  display: flex;
 }
 .wrap {
  width: 100%;
  height: auto;
  background: #fff;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
 }
 .box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
 }
 .group {
  width: 100%;
  height: auto;
 }
</style>
