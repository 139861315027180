import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { getToken } from './utils/auth'
import globalComponent from './components/commonComponents/register'

// 富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// 导入全局指令运行
import directivePlugin from './utils/usePermission'

Vue.use(directivePlugin)

Vue.use(VueQuillEditor)


Vue.use(globalComponent)
Vue.use(ElementUI, { size: 'small', zIndex: 3000 })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created () {
    const token = getToken()
    if (token) {
      // 初始化基本信息处理
      this.$store.dispatch('user/user/load')
      // 处理路由 得到每一级的路由设置
      this.$store.dispatch('system/menu/getMenu')
    }
  },
  mounted () {
  }
}).$mount('#app')
