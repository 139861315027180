// api配置模板
// export const apisConfig = {
//   'apiName': {
//     'url': 'api/url/{0}/{1}', // 其中{*}为动态url参数的占位符，其数量和位置根据实际url配置
//     'method': 'post', // 可选，默认为post
//     'useToken': false, // 可选，表示该请求是否带token，默认为true
//     'timeout': 30000, // 可选，服务超时时间设置
//     'headers': // 可选，默认为空
//     {
//       'Content-Type': 'multipart/form-data' // 示例
//     }
//   }
// }

// 菜单、角色、字典

export const systemApisConfig = {
  addMenu: {
    url: '/api/web/sys/menu/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delMenu: {
    url: '/api/web/sys/menu/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  putMenu: {
    url: '/api/web/sys/menu/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getDetailMenu: {
    url: '/api/web/sys/menu/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getRole: {
    url: '/api/web/sys/role/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getAllRole: {
    url: '/api/web/sys/role/searchNoPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addRole: {
    url: '/api/web/sys/role/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delRole: {
    url: '/api/web/sys/role/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  putRole: {
    url: '/api/web/sys/role/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getRouters: {
    url: '/api/web/sys/menu/getRouters',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getDetailRole: {
    url: '/api/web/sys/role/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getRoleTypeCodeList: {
    url: '/api/web/sys/role/searchRoleTypeCodeList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getRoleNhcList: { // 卫健委角色列表
    url: '/api/web/sys/role/searchRoleNhcList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getRoleNurseryList: { // 根据卫健委角色获取园所管理员列表
    url: '/api/web/sys/role/searchRoleNurseryList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getTrack: {
    url: '/api/web/sys/track/event/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getTrackLogin: {
    url: '/api/web/sys/track/event/searchLoginBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -------------------字典---------------------
  searchBaseListDict: { // 有分页
    url: '/api/web/file/dict/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchNoBaseListDict: { // 无分页
    url: '/api/web/file/dict/searchNoPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addDict: {
    url: '/api/web/file/dict/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getDictDetail: {
    url: '/api/web/file/dict/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editDict: {
    url: '/api/web/file/dict/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delDict: {
    url: '/api/web/file/dict/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
}
