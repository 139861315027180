import { Message } from 'element-ui'
import axios from 'axios'
import { getToken } from '@/utils/auth'
import LoadingService from './Loading'
import routes from '@/router'

const loadingService = new LoadingService()

const urlData = [] // 不走全局loding的接口

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API, // api
  timeout: 600000 // 请求超时时间
})

// 在异常处理中，我们可以 根据error.response.status返回的状态码进行操作，比如接口返回401作权限操作等
const err = (error) => {
  if (error.response) {
    if (error.response.status !== 200) {
      // 接口接口返回的状态码，获取对应的提示消息
      // const errorMessage = errorCode(error.response)
      // console.log(errorMessage)
      return
    }
  }
  return Promise.reject(error)
}

// 请求拦截中，我们可以对请求头作处理，比如所有的请求都加一个token等
service.interceptors.request.use(config => {
  config.headers.systemKey = process.env.VUE_APP_SYSTEMKEY
  const token = getToken()
  if (token) {
    config.headers['x-authentication-token'] = token
  }
  if (!(config.data instanceof Object && config.data.LoadingState)) {
    if (!urlData.includes(config.url)) {
      loadingService.showLoading()
    }
  }
  return config
}, err)

// 返回数据拦截中，我们可以对数据做一些小小的处理，比如后端所有接口返回的数据是这种类型:{data: {...}}，那我们就可以把data这一层给过滤掉
service.interceptors.response.use((response) => {
  if (!urlData.includes(response.config.url)) {
    loadingService.hideLoading()
  }
  return response.data
}, error => {
  loadingService.hideLoading()
  const status = error.response && error.response.status
  const response = error.response
  switch (status) {
    case 400:
      error.message = '请求错误'
      break
    case 401:
      error.message = '没有权限'
      break
    case 403:
      error.message = '拒绝访问'
      break
    case 404:
      error.message = `请求地址出错: ${error.response.config.url}`
      break
    case 408:
      error.message = '请求超时'
      break
    case 500:
      if (response.data && (response.data.msg || response.data.message)) {
        error.message = response.data.msg || response.data.message
        if (response.data && response.data.code === 2) { // token异常
          routes.push('/login')
        }
      } else {
        error.message = response.data || '网络异常'
      }
      break
    case 501:
      error.message = '服务未实现'
      break
    case 502:
      error.message = '网关错误'
      break
    case 503:
      error.message = '服务不可用'
      break
    case 504:
      error.message = '网关超时'
      break
    case 505:
      error.message = 'HTTP版本不受支持'
      break
    default:
      break
  }
  Message.error(error.message)
  return Promise.reject(error)

})

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
 function createRequestFunction (service) {
  return function (config) {
    const token = getToken()
    const configDefault = {
      headers: {
        'x-authentication-token': token,
        systemKey: process.env.VUE_APP_SYSTEMKEY
      },
      timeout: 5000000,
      baseURL: process.env.VUE_APP_API,
      data: {}
    }
    return service(Object.assign(configDefault, config))
  }
}

// 用于真实网络请求的实例和请求方法
export const request = createRequestFunction(service)
