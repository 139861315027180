<template>
  <el-col class="item" :span="8">
    <el-col :span="6">
      <div class="label">{{ label }}：</div>
    </el-col>
    <el-col :span="16">
      <slot></slot>
    </el-col>
  </el-col>
</template>
<script>
  export default {
    name: 'SearchFormItem',
    props: {
      label: {
        type: String,
        default: ''
      }
    }
  }
</script>
<style scoped>
.label {
  height: 30px;
  line-height: 30px;
  text-align: right;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.item {
  margin-bottom: 24px;
}
</style>
