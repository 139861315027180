// 与宝宝相关的其他信息 /联系人/病例/体检/疫苗/健康档案

export const studentRelatedApisConfig = {
  // -------------------联系人---------------------
  searchBaseListStudentFamily: { // 有分页
    url: '/api/web/cla/student/family/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addStudentFamily: {
    url: '/api/web/cla/student/family/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getStudentFamilyDetail: {
    url: '/api/web/cla/student/family/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editStudentFamily: {
    url: '/api/web/cla/student/family/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -------------------病例---------------------
  searchBaseListMedical: {
    url: '/api/web/cla/student/medical/history/searchNoPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addMedical: {
    url: '/api/web/cla/student/medical/history/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getMedicalDetail: {
    url: '/api/web/cla/student/medical/history/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editMedical: {
    url: '/api/web/cla/student/medical/history/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delMedical: {
    url: '/api/web/cla/student/medical/history/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -------------------体检---------------------
  searchBaseListHealth: {
    url: '/api/web/cla/student/health/examination/searchNoPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addHealth: {
    url: '/api/web/cla/student/health/examination/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getHealthDetail: {
    url: '/api/web/cla/student/health/examination/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editHealth: {
    url: '/api/web/cla/student/health/examination/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delHealth: {
    url: '/api/web/cla/student/health/examination/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // 字典
  searchNoPageListDict: {
    url: '/api/web/file/dict/searchNoPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -------------------疫苗---------------------
  addVaccine: {
    url: '/api/web/cla/student/vaccine/card/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getVaccineDetail: {
    url: '/api/web/cla/student/vaccine/card/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editVaccine: {
    url: '/api/web/cla/student/vaccine/card/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -------------------健康档案---------------------
  getRecordTable: {
    url: '/api/web/cla/student/health/record/searchOptionList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getRecordDetail: {
    url: '/api/web/cla/student/health/record/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  }
}
