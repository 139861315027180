// 园所管理 // 园所/课程、课程类型、章节/公告/预约申请

export const nurseryApisConfig = {
  getNurseryDetail: {
    url: '/api/web/nursery/nursery/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchNurseryBaseList: {
    url: '/api/web/nursery/nursery/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchListByCurrentUser: {
    url: '/api/web/nursery/nursery/searchListByCurrentUser',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  putNursery: {
    url: '/api/web/nursery/nursery/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addNursery: {
    url: '/api/web/nursery/nursery/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delNursery: {
    url: '/api/web/nursery/nursery/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  nurseryEveryYear: { // 每年
    url: '/api/web/nursery/nursery/report/numberEveryYear',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  nurseryYear: { // 当年
    url: '/api/web/nursery/nursery/report/numberByYear',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // ------------------------课程-----------------------
  searchCourseBaseList: {
    url: '/api/web/nursery/course/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchCourseNoPageList: {
    url: '/api/web/nursery/course/searchNoPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addCourse: {
    url: '/api/web/nursery/course/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getCourseDetail: {
    url: '/api/web/nursery/course/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editCourse: {
    url: '/api/web/nursery/course/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delCourse: {
    url: '/api/web/nursery/course/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  courseAuthorizeNursery: {
    url: '/api/web/nursery/nurseryCourse/courseAuthorizeNursery',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchCourseAuthorizeNurseryBycurrentUser: {
    url: '/api/web/nursery/nurseryCourse/searchCourseAuthorizeNurseryBycurrentUser',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // ------------------------课程类型-----------------------
  searchCourseTypeBaseList: {
    url: '/api/web/nursery/courseType/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchCourseTypeNoPageList: {
    url: '/api/web/nursery/courseType/searchList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addCourseType: {
    url: '/api/web/nursery/courseType/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getCourseTypeDetail: {
    url: '/api/web/nursery/courseType/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editCourseType: {
    url: '/api/web/nursery/courseType/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delCourseType: {
    url: '/api/web/nursery/courseType/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // ------------------------章节-----------------------
  searchCourseChapterBaseList: {
    url: '/api/web/nursery/courseChapter/searchTreeTable',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addCourseChapter: {
    url: '/api/web/nursery/courseChapter/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getCourseChapterDetail: {
    url: '/api/web/nursery/courseChapter/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editCourseChapter: {
    url: '/api/web/nursery/courseChapter/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delCourseChapter: {
    url: '/api/web/nursery/courseChapter/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // ------------------------公告-----------------------
  searchNoticeBaseList: {
    url: '/api/web/nursery/notice/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addNotice: {
    url: '/api/web/nursery/notice/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getNoticeDetail: {
    url: '/api/web/nursery/notice/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editNotice: {
    url: '/api/web/nursery/notice/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delNotice: {
    url: '/api/web/nursery/notice/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // ------------------------预约-----------------------
  searchAppointmentBaseList: {
    url: '/api/web/nursery/appointment/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addAppointment: {
    url: '/api/web/nursery/appointment/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getAppointmentDetail: {
    url: '/api/web/nursery/appointment/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editAppointment: {
    url: '/api/web/nursery/appointment/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
    // ------------------------资源文件管理-----------------------
  delFileMgmt: {
    url: '/api/web/mgmt-file/del',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addFileMgmt: {
    url: '/api/web/mgmt-file/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  putFileMgmt: {
    url: '/api/web/mgmt-file/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getFileMgmt: {
    url: '/api/web/mgmt-file/detail',
    method: 'get'
  },
  getFileMgmtNurseryBycurrentUser: {
    url: '/api/web/mgmt-file/searchFileMgmtNurseryBycurrentUser',
    method: 'get'
  },
  fileMgmtAuthorizeNursery: {
    url: '/api/web/mgmt-file/fileMgmtAuthorizeNursery',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  downZip: {
    url: '/api/web/mgmt-file/downloadFile',
    method: 'get',
    responseType: 'blob'
  },
}
