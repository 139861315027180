export default {
  namespaced: true,
  state: {
    layoutType: 'horizontal', // 页面布局：目前准备做两种，一种上下布局 一种左右 horizontal / vertical
    menuType: 'vertical', // 注意：跟页面布局相反
    isCollapse: false, // 菜单展开/折叠 menuType
    menuWidth: '200px', // 菜单宽度
    menuMinWidth: '60px', // 菜单最小宽度
    menuTheme: 'black', // 整体风格，黑色 black / 白色 white
    menuStyle: {
      bgColor: '#fff',
      textColor: '#000',
      activeColor: '#4E6EF2'
    }
  },
  getters: {},
  actions: {},
  mutations: {
    changeLayoutType (state) {
      state.layoutType = state.layoutType === 'horizontal' ? 'vertical' : 'horizontal'
      state.menuType = state.layoutType === 'horizontal' ? 'vertical' : 'horizontal'
    },
    changeIsCollapse (state) {
      state.isCollapse = !state.isCollapse
    }
  }
}
