import { requestApi } from '@/api'
export default {
  namespaced: true,
  state: {
    userInfo: null,
    roles: null,
    isAdmin: false
  },
  getters: {},
  actions: {
    async getUserInfo ({ commit, dispatch }) {
      await requestApi('getInfo', {}).then(res => {
        commit('setUserInfo', res.data.user)
        commit('setRoles', res.data.roles)
        commit('isAdmin', res.data.roles)
      })
    },
    async load ({ dispatch }) {
      await dispatch('getUserInfo')
    }
  },
  mutations: {
    setUserInfo (state, data) {
      state.userInfo = data
    },
    setRoles (state, data) {
      state.roles = data
    },
    isAdmin (state, data) {
      if (!data || !data.length) {
        state.isAdmin = false
      } else {
        const arr = data.filter(item => item.holeCode === 'admin')
        if (arr && arr.length) {
          state.isAdmin = true
        }
      }
    }
  }
}
