/**
 * 请求API
 * @param {Object} request 不可为空，执行请求的方法
 * @param {Object} apisConfig 不可为空，api配置对象
 * @param {String} apiName 不可为空，apisConfig中配置的API名称
 * @param {String} apiRootUrl 可选，API请求根地址
 * @param {Array} apiUrlParams 可选，用于填充API的url中须动态设置的值，如/api/getInfo/{projectId}
 * @param {Object} data 可选，请求附带的数据
 * @param {Function} callback 可选，请求完成回调函数
 * @returns {Promise} Promise
 */
 export function requestService ({
  request,
  apisConfig,
  apiName,
  apiRootUrl,
  apiUrlParams,
  data,
  callback
}) {
  // 判断是否有该api配置信息
  if (!request || !apisConfig || !apiName || !apisConfig[apiName]) {
    const error = new Error('警告：没有找到“' + apiName + '”对应的API配置信息，请确认拼写正确。')
    const errorPromise = Promise.reject(error)
    if (callback) {
      errorPromise
        .catch(error => {
          callback(error)
        })
    }
    return errorPromise
  }
  // 构造请求配置对象

  // const apiConfig = {
  //   ...apisConfig[apiName]
  // };
  const apiConfig = {}
  const apiConfigObj = apisConfig[apiName]
  for (const item in apiConfigObj) {
    apiConfig[item] = apiConfigObj[item]
  }
  const requestConfig = {
    url: getRequestUrl({
      apisConfig,
      apiName,
      apiRootUrl,
      apiUrlParams
    }),
    method: apiConfig.method || 'post',
    headers: apiConfig.headers || null,
    responseType: apiConfig.responseType || 'json'
  }

  if (apiConfig.method === 'get') {
    requestConfig.params = data
  } else {
    requestConfig.data = data
  }
  if (apiConfig.timeout) {
    requestConfig.timeout = apiConfig.timeout
  }
  if (apiConfig.baseURL) {
    requestConfig.baseURL = apiConfig.baseURL
  }

  const requestPromise = request(requestConfig)
  if (callback) {
    requestPromise
      .then((res) => {
        callback(res)
      })
      .catch(error => {
        callback(error)
      })
  }
  return requestPromise
}

export function getRequestUrl ({
  apisConfig,
  apiName,
  apiRootUrl,
  apiUrlParams
}) {
  // 判断是否有该api配置信息
  if (!apisConfig || !apiName || !apisConfig[apiName]) {
    return null
  }
  // 构造请求配置对象
  // const apiConfig = {
  //   ...apisConfig[apiName]
  // };
  const apiConfig = {}
  const apiConfigObj = apisConfig[apiName]
  for (const item in apiConfigObj) {
    apiConfig[item] = apiConfigObj[item]
  }
  if (apiUrlParams) {
    // 替换url中的占位符，按规则拼接url
    for (let i = 0; i < apiUrlParams.length; i++) {
      apiConfig.url = apiConfig.url.replace('{' + i + '}', apiUrlParams[i])
    }
  }

  return apiRootUrl ? (apiRootUrl + apiConfig.url) : apiConfig.url
}

export function convertObject2FormData (obj) {
  const formData = new FormData()
  if (!obj) return formData
  for (const item in obj) {
    formData.append(item, obj[item])
  }
  return formData
}
