/**
 * 在主框架内显示
 */
const frameIn = []

/**
 * 在主框架之外显示
 */
const frameOut = [
  {
    path: '/',
    redirect: { name: 'index' }
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login-page.vue')
  },
  {
    path: '/index',
    name: 'index',
    redirect: { name: 'work' },
    meta: {
      auth: true,
      title: '首页'
    },
    component: () => import('@/views/home-page.vue'),
    children: [
      {
        path: '/work',
        name: 'work',
        meta: {
          auth: true,
          title: '工作台'
        },
        component: () => import('@/views/page/work/work-page.vue'),
      }, {
        path: '/nursery/nursery-list/detail',
        name: 'nursery-detail',
        meta: {
          auth: true,
          title: '园所详情'
        },
        component: () => import('@/views/page/nursery/nursery/nursery-detail.vue'),
      }, {
        path: '/notice/notice-list/detail',
        name: 'notice-detail',
        meta: {
          auth: true,
          title: '公告详情'
        },
        component: () => import('@/views/page/nursery/notice/notice-detail.vue'),
      }, {
        path: '/ask/ask-list/detail',
        name: 'ask-detail',
        meta: {
          auth: true,
          title: '请假详情'
        },
        component: () => import('@/views/page/daily/ask/ask-detail.vue'),
      }, {
        path: '/recipe/recipe-list/detail',
        name: 'recipe-detail',
        meta: {
          auth: true,
          title: '食谱详情'
        },
        component: () => import('@/views/page/daily/recipe/recipe-detail.vue'),
      }, {
        path: '/recruit/recruit-list/detail',
        name: 'recruit-detail',
        meta: {
          auth: true,
          title: '招生详情'
        },
        component: () => import('@/views/page/nursery/recruit/recruit-detail.vue'),
      }, {
        path: '/appointment/appointment-list/detail',
        name: 'appointment-detail',
        meta: {
          auth: true,
          title: '预约详情'
        },
        component: () => import('@/views/page/nursery/appointment/appointment-detail.vue'),
      }, {
        path: '/course/course-list/detail',
        name: 'course-detail',
        meta: {
          auth: true,
          title: '课程详情'
        },
        component: () => import('@/views/page/nursery/course/course-detail.vue'),
      }, {
        path: '/courseType/courseType-list/detail',
        name: 'courseType-detail',
        meta: {
          auth: true,
          title: '课程分类详情'
        },
        component: () => import('@/views/page/nursery/courseType/courseType-detail.vue'),
      }, {
        path: '/promote/promote-list/detail',
        name: 'promote-detail',
        meta: {
          auth: true,
          title: '课程分类详情'
        },
        component: () => import('@/views/page/nursery/promote/promote-detail.vue'),
      }, {
        path: '/info/teacher-list/detail',
        name: 'teacher-detail',
        meta: {
          auth: true,
          title: '教师详情'
        },
        component: () => import('@/views/page/info/teacher/teacher-detail.vue'),
      }
    ]
  }
]

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '*',
    name: '404',
    component: () => import('@/views/error/404-page.vue')
  }
]

// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut,
  ...errorPage
]