// 信息管理 教师/家长/学生 /文件 

export const infoApisConfig = {
  // -------------------教师---------------------
  searchBaseListTeacher: { // 有分页
    url: '/api/web/cla/teacher/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchNoBaseListTeacher: { // 无分页
    url: '/api/web/cla/teacher/searchNoPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addTeacher: {
    url: '/api/web/cla/teacher/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getTeacherDetail: {
    url: '/api/web/cla/teacher/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editTeacher: {
    url: '/api/web/cla/teacher/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -------------------家长---------------------
  searchBaseListFamily: { // 有分页
    url: '/api/web/cla/family/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchNoBaseListFamily: { // 无分页
    url: '/api/web/cla/family/searchNoPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addFamily: {
    url: '/api/web/cla/family/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getFamilyDetail: {
    url: '/api/web/cla/family/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editFamily: {
    url: '/api/web/cla/family/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -------------------学生---------------------
  searchBaseListStudent: { // 有分页
    url: '/api/web/cla/student/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchNoBaseListStudent: { // 无分页
    url: '/api/web/cla/student/searchNoPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addStudent: {
    url: '/api/web/cla/student/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getStudentDetail: {
    url: '/api/web/cla/student/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editStudent: {
    url: '/api/web/cla/student/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  studentReport: {
    url: '/api/web/cla/student/report/student',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // ---------------------文件-----------------------
  uploadFile: {
    url: '/api/web/file/importFile',
    method: 'post',
    // headers: {
    //   'Content-Type': 'multipart/form-data'
    // }
  },
  getFile: {
    url: '/api/web/file/downloadFile/{0}/{1}/{2}', // systemFlag businessTableName fileStoreId
    method: 'get',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json,charset=UTF-8'
    }
  },
  uploadVideo: {
    url: '/api/web/file/importFileVideo',
    method: 'post',
  },
    // -------------------资源文件管理---------------------
  searchFileMgmtList: { // 有分页
    url: '/api/web/mgmt-file/searchNoPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchFileVideoList: { // 有分页
    url: '/api/web/app/video/searchPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  downFileVideo: {
    url: '/api/web/app/video/export',
    method: 'post',
    responseType: 'blob'
  },
}
