// 日常 // 宝宝请假/食谱

export const dailyApisConfig = {
  getRecordDetail: {
    url: '/api/web/cla/leave/record/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchRecordBaseList: {
    url: '/api/web/cla/leave/record/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  putRecord: {
    url: '/api/web/cla/leave/record/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addRecord: {
    url: '/api/web/cla/leave/record/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delRecord: {
    url: '/api/web/cla/leave/record/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  approveRecord: {
    url: '/api/web/cla/leave/record/approve',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  rejectRecord: {
    url: '/api/web/cla/leave/record/reject',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  submitRecord: {
    url: '/api/web/cla/leave/record/submit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -----------------------------食谱--------------------------------
  getRecipeDetail: {
    url: '/api/web/cla/recipe/weekly/plan/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchRecipeBaseList: {
    url: '/api/web/cla/recipe/weekly/plan/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  putRecipe: {
    url: '/api/web/cla/recipe/weekly/plan/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addRecipe: {
    url: '/api/web/cla/recipe/weekly/plan/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delRecipe: {
    url: '/api/web/cla/recipe/weekly/plan/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  releaseRecipe: {
    url: '/api/web/cla/recipe/weekly/plan/release',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  approveRecipe: {
    url: '/api/web/cla/recipe/weekly/plan/approve',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  rejectRecipe: {
    url: '/api/web/cla/recipe/weekly/plan/reject',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  submitRecipe: {
    url: '/api/web/cla/recipe/weekly/plan/submit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  }
}