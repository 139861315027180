// api配置模板
// export const apisConfig = {
//   'apiName': {
//     'url': 'api/url/{0}/{1}', // 其中{*}为动态url参数的占位符，其数量和位置根据实际url配置
//     'method': 'post', // 可选，默认为post
//     'useToken': false, // 可选，表示该请求是否带token，默认为true
//     'timeout': 30000, // 可选，服务超时时间设置
//     'headers': // 可选，默认为空
//     {
//       'Content-Type': 'multipart/form-data' // 示例
//     }
//   }
// }

export const userApisConfig = {
  login: { // 登录
    url: '/api/public/sys/account/login',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editPassword: { // 修改密码
    url: '/api/public/sys/account/resetPassword',
    method: 'post'
  },
  editUserPassword: { // 重置用户密码
    url: '/api/web/sys/user/resetPassword',
    method: 'post'
  },
  getInfo: { // 查询用户详情
    url: '/api/web/sys/user/getInfo',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getMenu: { // 菜单
    url: '/api/web/sys/menu/searchTreeTable',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchBaseUserList: { // list 用户基本信息查询
    url: '/api/web/sys/user/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addUser: {
    url: '/api/web/sys/user/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delUser: {
    url: '/api/web/sys/user/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  putUser: {
    url: '/api/web/sys/user/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getDetailUser: {
    url: '/api/web/sys/user/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  importUserFile: {
    url: '/api/web/mgmt-file/user/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
}
