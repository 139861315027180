// 年级、班级管理、班级幼儿、班级教师、周计划模版

export const claApisConfig = {
  // -------------------年级---------------------
  getPreschoolTypeDetail: {
    url: '/api/web/cla/preschool/type/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchPreschoolTypeBaseList: { // list
    url: '/api/web/cla/preschool/type/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  putPreschoolType: {
    url: '/api/web/cla/preschool/type/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  searchNoPageListTypeBaseList: { // list
    url: '/api/web/cla/preschool/type/searchNoPageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -------------------班级---------------------
  searchPreschoolBaseList: { // list
    url: '/api/web/cla/preschool/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addPreschool: {
    url: '/api/web/cla/preschool/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getPreschoolDetail: {
    url: '/api/web/cla/preschool/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editPreschool: {
    url: '/api/web/cla/preschool/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  preschoolReport: {
    url: '/api/web/cla/preschool/report/preschool',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -------------------班级幼儿---------------------
  selectUndistributedStudent: { // 未分配
    url: '/api/web/cla/preschool/student/selectUndistributedStudent',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addPreschoolStudent: {
    url: '/api/web/cla/preschool/student/addList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getPreschoolStudent: { // 已分配
    url: '/api/web/cla/preschool/student/selectAssignedStudent',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delPreschoolStudent: {
    url: '/api/web/cla/preschool/student/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -------------------班级教师---------------------
  selectUndistributedTeacher: { // 未分配
    url: '/api/web/cla/preschool/teacher/selectUndistributedTeacher',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addPreschoolTeacher: {
    url: '/api/web/cla/preschool/teacher/addList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getPreschoolTeacher: { // 已分配
    url: '/api/web/cla/preschool/teacher/selectAssignedTeacher',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delPreschoolTeacher: {
    url: '/api/web/cla/preschool/teacher/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  // -------------------周计划模版---------------------
  searchBaseListWeeklyPlanMould: {
    url: '/api/web/cla/preschool/weekly/plan/mould/searchBaseList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  addWeeklyPlanMould: {
    url: '/api/web/cla/preschool/weekly/plan/mould/add',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getWeeklyPlanMould: {
    url: '/api/web/cla/preschool/weekly/plan/mould/detail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delWeeklyPlanMould: {
    url: '/api/web/cla/preschool/weekly/plan/mould/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  editWeeklyPlanMould: {
    url: '/api/web/cla/preschool/weekly/plan/mould/edit',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  }
}
