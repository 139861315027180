import { requestApi } from '@/api'
import router from '@/router'
export default {
  namespaced: true,
  state: {
    menuArr: [],
    routerArr: [],
    headerMenu: [],
    menuArrAll: [], // 所有菜单 授权用
    btnRoles: [] // 按钮权限
  },
  getters: {},
  actions: {
    async getMenu ({ commit }) {
      // await requestApi('getMenu', {screenSize: 'large'}).then(res => {
      //   commit('setMenuArrAll', res.data)
      //   commit('setMenuArr', res.data)
      //   commit('setRouterArr', res.data)
      // })
      await requestApi('getRouters', {screenSize: 'large'}).then(res => {
        commit('setMenuArr', res.data)
        commit('setRouterArr', res.data)
      })
    }
  },
  mutations: {
    setMenuArrAll (state, data) {
      state.menuArrAll = data
    },
    setMenuArr (state, data) {
      state.menuArr = data
    },
    setRouterArr (state, data) {
        const newData = []
        const btns = []
        function updataRouter (list) {
            if (list.menuType === 'menu') {
                newData.push({
                  path: list.path,
                  name: list.component,
                  meta: {
                    auth: true,
                    title: list.menuName
                  },
                  component: () => import('@/views/page' + list.componentPath)
                }) 
            } else if (list.menuType === 'button') {
              btns.push(list.component)
            }
            if (list.children) {
                list.children.forEach(v => {
                    updataRouter(v)
                })
            }
        }
        data.forEach(item => {
            updataRouter(item)
        })
        state.routerArr = newData
        state.btnRoles = btns
       // console.log(state.btnRoles)
        newData.forEach(item => {
          router.addRoute("index", item);
        })
    },
    setHeaderMenu (state, data) {
      state.headerMenu = data
    }
  }
}
