/**
 * Created by supervisor on 2019/9/12
 */
 import { Loading } from 'element-ui'
 import Vue from 'vue'
 
 class loadingService {
   constructor () {
     this.loadingInstance = null
     this.loadingCount = 0
   }
 
   showLoading () {
     if (this.loadingInstance === null) {
       this.loadingInstance = Loading.service({
         fullscreen: true,
         lock: true,
         background: 'rgba(0,0,0,.5)',
         text: 'Loading...',
         spinner: 'el-icon-loading'
       })
     }
     this.loadingCount++
   }
 
   hideLoading () {
     Vue.nextTick(() => {
       this.loadingCount--
       if (this.loadingInstance !== null && this.loadingCount < 1) {
         this.loadingInstance.close()
         this.loadingInstance = null
       }
     })
   }
 }
 export default loadingService
 