import { request } from './request'
import { requestService } from './util.request.js'
import { userApisConfig } from './user'
import { systemApisConfig } from './system'
import { claApisConfig } from './cla'
import { nurseryApisConfig } from './nursery'
import { infoApisConfig } from './info'
import { dailyApisConfig } from './daily'
import { studentRelatedApisConfig } from './student-related'
export function requestApi (apiName, data, callback, apiUrlParams) {
  return requestService({
    request,
    apisConfig: { ...userApisConfig, ...systemApisConfig, ...claApisConfig, ...nurseryApisConfig, ...infoApisConfig, ...dailyApisConfig, ...studentRelatedApisConfig },
    apiName,
    apiUrlParams,
    data,
    callback
  })
}
